import axios from 'axios'

import storageService from '../../services/storageService'
import { ErrorCodes } from '../../config/ErrorCodes'
import { EnvConfig } from '../../config/EnvConfig'

const storedAccessToken = storageService.getAccessToken()

let http = null
if (storedAccessToken) {
  http = axios.create({
    baseURL: EnvConfig.API_URL,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${storedAccessToken}`,
    },
  })
} else {
  http = axios.create({
    baseURL: EnvConfig.API_URL,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
}

http.interceptors.request.use(
  async (req) => {
    return Promise.resolve(req)
  },
  (err) => {
    return Promise.reject(err)
  }
)

http.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    switch (err.response.status) {
      case 401:
        if (
          err.request.responseURL.indexOf('user') < 0 &&
          err.request.responseURL.indexOf('login') < 0 &&
          err.request.responseURL.indexOf('password') < 0
        ) {
          storageService.clearTokens()

          const errorCode = err.response.data.messageCode
          if (
            errorCode !== ErrorCodes.USER_LOCKED_TO_LOGIN &&
            errorCode !== ErrorCodes.WRONG_CREDENTIALS
          ) {
            window.location = '/'
          }
        }
        break
      default:
        break
    }
    return Promise.reject(err)
  }
)

export default http
