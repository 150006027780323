import React from 'react'

import AuthProvider from './src/context/authProvider'

import { parseQueryString } from './src/utils/generic'
import { setCookie } from './src/utils/cookies'

export const wrapRootElement = (props) => {
  return <AuthProvider {...props} />
}

export const onRouteUpdate = ({ location }) => {
  const params = parseQueryString(location.search)
  if (params.btag) {
    setCookie('btag', params.btag)
  }
  if (params.clickid) {
    setCookie('clickid', params.clickid)
  }
}
