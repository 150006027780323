export const generateUEID = () => {
  let first = (Math.random() * 46656) | 0
  let second = (Math.random() * 46656) | 0
  first = ('000' + first.toString(36)).slice(-3)
  second = ('000' + second.toString(36)).slice(-3)

  return first + second
}

export const parseQueryString = (string) => {
  return string
    .slice(1)
    .split('&')
    .map((queryParam) => {
      let kvp = queryParam.split('=')
      return { key: kvp[0], value: kvp[1] }
    })
    .reduce((query, kvp) => {
      query[kvp.key] = kvp.value
      return query
    }, {})
}
