import { coreApi } from './base'

export const doLogin = async (username, password) => {
  try {
    const response = await coreApi.post('/auth/login', { username, password })
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const doLogout = async () => {
  const response = await coreApi.post('/auth/logout')
  const data = await response.data
  return data
}

export const getWallet = async () => {
  const response = await coreApi.get('/wallet')
  const data = await response.data
  return data
}

export const doForgotPassword = async (email, returnUrl) => {
  try {
    const response = await coreApi.get(
      `/auth/password/reset/${email}?password_reset_url=${returnUrl}`
    )
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const doActivate = async (token) => {
  try {
    const response = await coreApi.post(`/auth/activate/${token}/`)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const doResetPassword = async (model) => {
  try {
    const response = await coreApi.post(`/auth/password/reset/`, model)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const doRegistration = async (model) => {
  try {
    const response = await coreApi.post(`/user`, model)
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const doRefreshToken = async (refreshToken) => {
  const response = await coreApi.post('/auth/refresh-token', {
    refresh_token: refreshToken,
  })
  return await response.data.data
}

export const getCurrentUser = async () => {
  try {
    const response = await coreApi.get('/user')
    const user = response.data.data
    return { ok: true, data: user }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}
