import React, {
  createContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react'
import jwt_decode from 'jwt-decode'

import { coreApi } from '../adapters/base'
import storageService from '../services/storageService'

import {
  doLogin,
  getCurrentUser,
  doLogout,
  doRefreshToken,
  getWallet,
} from '../adapters/auth'

export const AuthContext = createContext({
  isLoggedIn: false,
  user: null,
  getUserWallet: () => {},
  loginUser: () => {},
  logoutUser: () => {},
})

function setApiHeaders(token) {
  coreApi.defaults.headers['Authorization'] = `Bearer ${token}`
}

const AuthProvider = ({ children }) => {
  const intervalRef = useRef()
  const [user, setUser] = useState(null)

  const clearSession = useCallback(() => {
    setUser(false)
    delete coreApi.defaults.headers['Authorization']
    storageService.clearTokens()
    clearInterval(intervalRef.current)
  }, [intervalRef])

  const fetchUser = useCallback(() => {
    const id = setInterval(() => {
      getCurrentUser()
        .then((user) => {
          // temporary solution for balance in order to remove extra decimal values
          // example: 199.9999999 now is converted to 199.99 instead of 200.00
          if (user.data.wallet.amount % 1 !== 0) {
            const amount = Math.floor(user.data.wallet.amount * 100) / 100
            user.data.wallet.amount = amount
          }
          // end of temporary solution

          setUser(user.data)
          storageService.setUser(JSON.stringify(user.data))
        })
        .catch((_) => clearSession())
    }, 300000) // every 5 minutes call getCurrentUser

    intervalRef.current = id
  }, [intervalRef, clearSession])

  useEffect(() => {
    const storedAccessToken = storageService.getAccessToken()
    const storedUser = storageService.getUser()
    if (storedAccessToken && storedUser) {
      setUser(storedUser)
      setApiHeaders(storedAccessToken)
      fetchUser()
    } else {
      clearSession()
    }

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [clearSession, fetchUser, intervalRef])

  const loginUser = async (username, password) => {
    const response = await doLogin(username, password)

    if (!response.ok) return response

    if (response.ok) {
      storageService.removeValue('notification')
      storageService.setTokens(response.data.data)
      setApiHeaders(response.data.data.access_token)

      const user = await getCurrentUser()
      if (user.ok) {
        // temporary solution for balance in order to remove extra decimal values
        // example: 199.9999999 now is converted to 199.99 instead of 200.00
        if (user.data.wallet.amount % 1 !== 0) {
          const amount = Math.floor(user.data.wallet.amount * 100) / 100
          user.data.wallet.amount = amount
        }
        // end of temporary solution

        setUser(user.data)
        storageService.setUser(JSON.stringify(user.data))
        fetchUser()

        if (typeof window !== 'undefined') {
          window.dataLayer.push({
            event: 'Login',
            accountId: user.data.id,
          })
        }
      }
    }

    return response
  }

  const getUserWallet = () => {
    getWallet().then((res) => {
      let storedUser = storageService.getUser()

      // temporary solution for balance in order to remove extra decimal values
      // example: 199.9999999 now is converted to 199.99 instead of 200.00
      if (res.data.amount % 1 !== 0) {
        const amount = Math.floor(res.data.amount * 100) / 100
        res.data.amount = amount
      }
      // end of temporary solution

      storedUser.wallet = res.data

      setUser(storedUser)
      storageService.setUser(JSON.stringify(storedUser))
    })
  }

  const logoutUser = () => {
    doLogout()
      .then(() => {
        clearSession()
      })
      .catch((err) => {
        if (err.response.status === 401) {
          clearSession()
        }
      })
  }

  const refreshAccessToken = () => {
    const refreshToken = storageService.getRefreshToken()

    if (!refreshToken) return

    doRefreshToken(refreshToken)
      .then((response) => {
        storageService.setToken(response)
        refreshTokenOnceExpired()
      })
      .catch(() => {
        logoutUser()
      })
  }

  const refreshTokenOnceExpired = () => {
    const accessToken = storageService.getAccessToken()

    if (!accessToken) return

    const { exp: expiresAtInSeconds } = jwt_decode(accessToken)

    // Refresh token one minute before expiry
    const refreshTokenAfterInSeconds =
      expiresAtInSeconds - Date.now() / 1000 - 60
    const refreshTokenAfterInMilliseconds = refreshTokenAfterInSeconds * 1000

    setTimeout(() => refreshAccessToken(), refreshTokenAfterInMilliseconds)
  }

  let value = {
    loginUser,
    logoutUser,
    getUserWallet,
    isLoggedIn: user ? !!user : user,
    user,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

// eslint-disable-next-line
export default ({ element }) => <AuthProvider>{element}</AuthProvider>
