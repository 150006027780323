const storageService = {
  setTokens: function (tokenObj) {
    localStorage.setItem('@kto:access_token', tokenObj.access_token)
    localStorage.setItem('@kto:refresh_token', tokenObj.refresh_token)
  },

  getAccessToken: function () {
    if (typeof window === 'undefined') return
    return localStorage.getItem('@kto:access_token')
  },

  getRefreshToken: function () {
    return localStorage.getItem('@kto:refresh_token')
  },

  setUser: function (user) {
    localStorage.setItem('@kto:user', user)
  },

  getUser: function () {
    if (typeof window === 'undefined') return
    const userStorage = localStorage.getItem('@kto:user')
    if (userStorage && userStorage !== 'undefined') {
      return JSON.parse(userStorage)
    }
  },

  setLang: function (lang) {
    localStorage.setItem('gatsby-intl-language', lang)
  },

  setValue: function (key, value) {
    if (typeof window === 'undefined') return
    localStorage.setItem(`@kto:${key}`, value)
  },

  getValue: function (key) {
    if (typeof window === 'undefined') return
    return localStorage.getItem(`@kto:${key}`)
  },

  removeValue: function (key) {
    if (typeof window === 'undefined') return
    return localStorage.removeItem(`@kto:${key}`)
  },

  getLang: function () {
    if (typeof window === 'undefined') return
    return localStorage.getItem('gatsby-intl-language')
  },

  clearTokens: function () {
    localStorage.removeItem('@kto:access_token')
    localStorage.removeItem('@kto:refresh_token')
    localStorage.removeItem('@kto:user')
    localStorage.removeItem('@kto:activation-warning')
  },
}
export default storageService
