export const EnvConfig = {
  API_URL: process.env.GATSBY_KTO_API,
  SITE_URL: process.env.GATSBY_KTO_URL,
  GAME_LAUNCH_URL: process.env.GATSBY_KTO_GAME_LAUNCH_URL,
  CMS_API_URL: process.env.WORDPRESS_API_URL,
  TEMPORARY_CMS_API_URL: process.env.TEMPORARY_WORDPRESS_API_URL,
  CMS_URL: process.env.GATSBY_WORDPRESS_URL,
  SPORTS_WALLET_CODE: process.env.GATSBY_SPORTS_WALLET_CODE,
  GATSBY_GTM_CODE: process.env.GATSBY_GTM_CODE,
  GATSBY_SPORTS_URL: process.env.GATSBY_SPORTS_URL,
  GATSBY_GA_ANALYTICS_CODE: process.env.GATSBY_GA_ANALYTICS_CODE,
  GATSBY_ARETONET_ID: process.env.GATSBY_ARETONET_ID,
  GATSBY_TEADS_PIXEL_ID: process.env.GATSBY_TEADS_PIXEL_ID,
  GATSBY_LICENSE_SCRIPT: process.env.GATSBY_LICENSE_SCRIPT,
}
