export const getCookie = (name) => {
  if (typeof window === 'undefined') return
  var b = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
  return b ? b.pop() : ''
}

export const deleteCookie = (name, domain) => {
  setCookie(name, '', -50000, domain)
}

export const setCookie = (name, value, exdays, domain) => {
  var exdate = new Date()
  exdate.setDate(exdate.getDate() + exdays)

  value =
    escape(value) + (exdays == null ? '' : '; expires=' + exdate.toUTCString())
  if (domain) {
    value += '; domain=' + escape(domain) + ';path=/'
  }

  document.cookie = name + '=' + value + ';path=/'
}
