// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-templates-account-mobile-overview-js": () => import("./../../../src/templates/account/mobileOverview.js" /* webpackChunkName: "component---src-templates-account-mobile-overview-js" */),
  "component---src-templates-account-old-bets-js": () => import("./../../../src/templates/account/oldBets.js" /* webpackChunkName: "component---src-templates-account-old-bets-js" */),
  "component---src-templates-account-profile-js": () => import("./../../../src/templates/account/profile.js" /* webpackChunkName: "component---src-templates-account-profile-js" */),
  "component---src-templates-account-responsible-js": () => import("./../../../src/templates/account/responsible.js" /* webpackChunkName: "component---src-templates-account-responsible-js" */),
  "component---src-templates-account-settings-js": () => import("./../../../src/templates/account/settings.js" /* webpackChunkName: "component---src-templates-account-settings-js" */),
  "component---src-templates-account-transactions-js": () => import("./../../../src/templates/account/transactions.js" /* webpackChunkName: "component---src-templates-account-transactions-js" */),
  "component---src-templates-account-verification-js": () => import("./../../../src/templates/account/verification.js" /* webpackChunkName: "component---src-templates-account-verification-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog/categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog/category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog/post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-casino-casino-category-js": () => import("./../../../src/templates/casino/casinoCategory.js" /* webpackChunkName: "component---src-templates-casino-casino-category-js" */),
  "component---src-templates-casino-casino-game-js": () => import("./../../../src/templates/casino/casinoGame.js" /* webpackChunkName: "component---src-templates-casino-casino-game-js" */),
  "component---src-templates-casino-casino-js": () => import("./../../../src/templates/casino/casino.js" /* webpackChunkName: "component---src-templates-casino-casino-js" */),
  "component---src-templates-common-content-js": () => import("./../../../src/templates/common/content.js" /* webpackChunkName: "component---src-templates-common-content-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-on-boarding-activate-js": () => import("./../../../src/templates/onBoarding/activate.js" /* webpackChunkName: "component---src-templates-on-boarding-activate-js" */),
  "component---src-templates-on-boarding-forgot-password-js": () => import("./../../../src/templates/onBoarding/forgot-password.js" /* webpackChunkName: "component---src-templates-on-boarding-forgot-password-js" */),
  "component---src-templates-on-boarding-login-js": () => import("./../../../src/templates/onBoarding/login.js" /* webpackChunkName: "component---src-templates-on-boarding-login-js" */),
  "component---src-templates-on-boarding-register-js": () => import("./../../../src/templates/onBoarding/register.js" /* webpackChunkName: "component---src-templates-on-boarding-register-js" */),
  "component---src-templates-on-boarding-reset-js": () => import("./../../../src/templates/onBoarding/reset.js" /* webpackChunkName: "component---src-templates-on-boarding-reset-js" */),
  "component---src-templates-payments-deposit-js": () => import("./../../../src/templates/payments/deposit.js" /* webpackChunkName: "component---src-templates-payments-deposit-js" */),
  "component---src-templates-payments-withdraw-js": () => import("./../../../src/templates/payments/withdraw.js" /* webpackChunkName: "component---src-templates-payments-withdraw-js" */),
  "component---src-templates-promotions-promotion-detail-js": () => import("./../../../src/templates/promotions/promotionDetail.js" /* webpackChunkName: "component---src-templates-promotions-promotion-detail-js" */),
  "component---src-templates-promotions-promotions-js": () => import("./../../../src/templates/promotions/promotions.js" /* webpackChunkName: "component---src-templates-promotions-promotions-js" */),
  "component---src-templates-sports-sports-js": () => import("./../../../src/templates/sports/sports.js" /* webpackChunkName: "component---src-templates-sports-sports-js" */)
}

